import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const isBrowser = typeof window !== 'undefined'

class Template extends React.Component {
  render() {
    const { children } = this.props
    const isEn = isBrowser ? window.location.pathname.startsWith('/en/') : false
    const lang = isEn ? 'en' : 'it'

    const Container = styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-family: 'Nunito';
      max-width: 820px;
      width: 820px;
      margin: 0 auto;
      @media (max-width: 700px) {
        width: 80vw;
      }
    `
    const StyledLink = styled(Link)`
      position: relative;
      text-decoration: none;
      color: #000 !important;
      &:hover {
        text-decoration: none !important;
      }
    `
    const Title = styled.h1`
      display: flex;
      align-self: flex-start;
      font-weight: 300;
      font-family: 'Lato';
      letter-spacing: 0.3rem;
      font-size: 1.3vw !important;
      text-transform: uppercase;
      margin: 5vh 0 15vh 0;
      @media (max-width: 700px) {
        letter-spacing: 0.15rem;
        font-size: 11px !important;
      }
    `
    const Content = styled.div`
      /* font-weight: 400;
      font-family: 'EB Garamond';
      font-size: 1.4vw;
      line-height: 170%; */
    `
    return (
      <Container>
        <Title>
          <StyledLink to={'/'}>Andrea Martines</StyledLink>&nbsp;-&nbsp;
          <StyledLink to={'/blog/'}>Blog (2006-2013)</StyledLink>
        </Title>
        <Content className="content">{children}</Content>
      </Container>
    )
  }
}

export default Template
