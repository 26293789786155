import React from 'react'

const Css = `
body {
    background: #fff;
}
// @font-face {
//   font-family: "Nitti";
//   src: url("/Nitti-Regular.woff");
// }
// .selected {
//     background: #222;
//     color: #fff;
//     border-radius: 4px;
//     padding: 0px 5px 3px 4px;
//     }
.content {
    font-weight: 300;
    width: 100%;
    font-family: 'Nunito';
    font-size: 1.6vw;
    line-height: 170%;
}
.content h1 {
  font-weight: 700;
  font-family: 'Nunito';
  font-size: 3vw;
}
blockquote {
    font-style: italic;
}
a, a:visited {
  color: #cc0000;
}
a:hover {
  color: #cc0000 !important;
  text-decoration: underline !important;
}
.posts-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.posts-list li {
  float: left;
  width: 100% !important;
}
.posts-list li a, .posts-list li a:hover {
  color: #000 !important;
}
.posts-list li .date {
  font-size: 1.3vw;
  font-style: italic;
  float: left;
  width: 25%;
  text-align: right;
  padding-top: 2px;
}
.posts-list li .title {
  float: right;
  width: 70%;
  text-align: left;
}
@media (max-width: 700px) {
  .content {
      font-size: 18px;
  }
  .content h1 {
      font-size: 30px;
  }
  .content h2 {
      font-size: 22px;
  }
  img {
      max-width: 70vw;
  }
  blockquote {
      font-size: 85%;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      margin: 20px;
      line-height: 150%;
  }
  table {
      display: content !important;
      margin: 0;
      width: auto;
      max-width: 60vw;
  }
  .posts-list li .date {
    font-size: 9px;
    width: 30%;
  }
  .posts-list li .title {
    width: 65%;
  }
}
img {
  display: block;
}
`

export default Css
