export function filterHtml(allFile, html) {
  // var filteredHtml = html

  const re = /uploads\/(.*?)\"/g
  var m = 0
  var images = []
  do {
    m = re.exec(html)
    if (m) {
      images.push(m[1])
      // console.log(m[1])
    }
  } while (m)

  images.forEach(function (img) {
    allFile.edges.forEach(function (item) {
      if (item.node.relativePath === img) {
        html = html.replace(
          `../uploads/${item.node.relativePath}`,
          item.node.publicURL
        )
      }
    })
  })
  return html;
}
